<template>
  <div>
    <br>
    <v-card class="mx-auto px-6 py-8" width="344">
      <div class="mx-auto px-6" style="display: flex; flex-direction: column; justify-content: center; align-items: center; align-content: center; text-align: center;">
        <div class="mx-auto px-6" style="display: flex; flex-direction: row; justify-content: center; align-items: center; align-content: center; text-align: center;">
          <p @click="changeLanguage('pt-br')" style="margin-right: 20px; font-weight: bold; color: #0a7373; cursor: pointer;" class="flag-icon flag-icon-br"></p>
          <p @click="changeLanguage('en')" style="margin-left: 20px; font-weight: bold; color: #0a7373; cursor: pointer;" class="flag-icon flag-icon-us"></p>
        </div>
      </div>
      <v-card-title primary-title class="justify-center">
        <v-img :src="logo_url" max-width="250px" alt="wwcalc"/>
      </v-card-title>
      <v-card-subtitle>
        <InstallBanner/>
        <UpdateBanner/>
      </v-card-subtitle>
      <v-form v-if="!updateExists" v-model="form" @submit.prevent="onSubmit">
        <v-text-field v-model="email" :readonly="loading" :rules="[rules.email]" class="mb-2" clearable prepend-icon="mdi-account" outlined/>
        <v-text-field v-model="password" :readonly="loading" :rules="rules.password" clearable type="password" prepend-icon="mdi-lock" outlined :placeholder="selectedLanguage['login_component_text1']"/>
        <br>
        <v-btn :loading="loading" block color="primary" size="large" variant="elevated" @click="logginRequest">
          {{ selectedLanguage['login_component_text2'] }}
        </v-btn>
        <div class="overlay" v-if="logginMessage">
          <v-alert border="top" dense color="error" dark>
            {{logginMessage}}
          </v-alert>
        </div>
      </v-form>
      <br>
      <v-btn v-if="!updateExists" block color="secondary" size="large" variant="elevated" @click="$router.push({ name: 'register' })">
        {{ selectedLanguage['login_component_text3'] }}
      </v-btn>
      <br>
      <v-btn v-if="!updateExists" :loading="loading1" block color="secondary" size="large" variant="elevated" @click="resetRequest">
        {{ selectedLanguage['login_component_text4'] }}
      </v-btn>
      <div class="overlay" v-if="resetMessage">
        <v-alert border="top" dense color="error" dark>
          {{resetMessage}}
        </v-alert>
      </div>
      <div class="overlay" v-if="resetedPassword==true">
        <v-alert border="top" color="info" dark>
          {{ selectedLanguage['login_component_text5'] }}<br>
          {{ selectedLanguage['login_component_text6'] }}
        </v-alert>
      </div>
      <div class="overlay" v-else-if="resetedPassword==false">
        <br>
        <v-alert border="top" color="error" dark>
          {{ selectedLanguage['login_component_text7'] }}
        </v-alert>
      </div>
    </v-card>

    <div class="mx-auto px-6 py-6" width="344">
      <v-sheet v-if="selectedLanguage['profile_form_name_1'] === 'First Name'" class="mx-auto pa-1" min-width="23%" style="background: none;">
            <v-slide-group show-arrows>
                <template v-slot:next>
                    <v-icon color="black" style="font-size: 30px;" large>mdi-chevron-right</v-icon>
                </template>
                <template v-slot:prev>
                    <v-icon color="black" style="font-size: 30px;" large>mdi-chevron-left</v-icon>
                </template>
            <v-slide-item style="margin: 0 20px 0 20px;" name="1en">
                <video min-width="17%" max-width="50%" height="160px" controls poster="/img/thumbnail1_en.png">
                    <source src="/assets/video1_en.mp4" type="video/mp4">
                    Your browser does not support HTML video.
                </video>
            </v-slide-item>
            <v-slide-item style="margin: 0 20px 0 20px;" name="2en">
                <video min-width="17%" max-width="50%" height="160px" controls poster="/img/thumbnail2_en.png">
                    <source src="/assets/video2_en.mp4" type="video/mp4">
                    Your browser does not support HTML video.
                </video>
            </v-slide-item>
            <v-slide-item style="margin: 0 20px 0 20px;" name="3en">
                <div>
                    <video min-width="17%" max-width="50%" height="160px" controls poster="/img/thumbnail3_en.png">
                        <source src="/assets/video3_en.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                </div>
            </v-slide-item>
            <v-slide-item style="margin: 0 20px 0 20px;" name="4en">
                <div>
                    <video min-width="17%" max-width="50%" height="160px" controls poster="/img/thumbnail4_en.png">
                        <source src="/assets/video4_en.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                </div>
            </v-slide-item>
            <v-slide-item style="margin: 0 20px 0 20px;" name="5en">
                <div>
                    <video min-width="17%" max-width="50%" height="160px" controls poster="/img/thumbnail5_en.png">
                        <source src="/assets/video5_en.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                </div>
            </v-slide-item>
            <v-slide-item style="margin: 0 20px 0 20px;" name="6en">
                <div>
                    <video min-width="17%" max-width="50%" height="160px" controls poster="/img/thumbnail6_en.png">
                        <source src="/assets/video6_en.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                </div>
            </v-slide-item>
            <v-slide-item style="margin: 0 20px 0 20px;" name="7en">
                <div>
                    <video min-width="17%" max-width="50%" height="160px" controls poster="/img/thumbnail7_en.png">
                        <source src="/assets/video7_en.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                </div>
            </v-slide-item>
            <v-slide-item style="margin: 0 20px 0 20px;" name="8en">
                <div>
                    <video min-width="17%" max-width="50%" height="160px" controls poster="/img/thumbnail8_en.png">
                        <source src="/assets/video8_en.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                </div>
            </v-slide-item>
            <v-slide-item style="margin: 0 20px 0 20px;" name="9en">
                <div>
                    <video min-width="17%" max-width="50%" height="160px" controls poster="/img/thumbnail9_en.png">
                        <source src="/assets/video9_en.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                </div>
            </v-slide-item>
            </v-slide-group>
      </v-sheet>
      <v-sheet v-else class="mx-auto pa-1" min-width="23%" style="background: none;">
          <v-slide-group show-arrows>
              <template v-slot:next>
                  <v-icon color="black" style="font-size: 30px;" large>mdi-chevron-right</v-icon>
              </template>
              <template v-slot:prev>
                  <v-icon color="black" style="font-size: 30px;" large>mdi-chevron-left</v-icon>
              </template>
          <v-slide-item style="margin: 0 20px 0 20px;" name="1">
              <video min-width="17%" max-width="50%" height="160px" controls poster="/img/thumbnail1.png">
                  <source src="/assets/video_1.mp4" type="video/mp4">
                  Your browser does not support HTML video.
              </video>
          </v-slide-item>
          <v-slide-item style="margin: 0 20px 0 20px;" name="2">
              <video min-width="17%" max-width="50%" height="160px" controls poster="/img/thumbnail2.png">
                  <source src="/assets/video_2.mp4" type="video/mp4">
                  Your browser does not support HTML video.
              </video>
          </v-slide-item>
          <v-slide-item style="margin: 0 20px 0 20px;" name="3">
              <div>
                  <video min-width="17%" max-width="50%" height="160px" controls poster="/img/thumbnail3.png">
                      <source src="/assets/video_3.mp4" type="video/mp4">
                      Your browser does not support HTML video.
                  </video>
              </div>
          </v-slide-item>
          <v-slide-item style="margin: 0 20px 0 20px;" name="4">
              <div>
                  <video min-width="17%" max-width="50%" height="160px" controls poster="/img/thumbnail4.png">
                      <source src="/assets/video_4.mp4" type="video/mp4">
                      Your browser does not support HTML video.
                  </video>
              </div>
          </v-slide-item>
          <v-slide-item style="margin: 0 20px 0 20px;" name="5">
              <div>
                  <video min-width="17%" max-width="50%" height="160px" controls poster="/img/thumbnail5.png">
                      <source src="/assets/video_5.mp4" type="video/mp4">
                      Your browser does not support HTML video.
                  </video>
              </div>
          </v-slide-item>
          <v-slide-item style="margin: 0 20px 0 20px;" name="6">
              <div>
                  <video min-width="17%" max-width="50%" height="160px" controls poster="/img/thumbnail6.png">
                      <source src="/assets/video_6.mp4" type="video/mp4">
                      Your browser does not support HTML video.
                  </video>
              </div>
          </v-slide-item>
          <v-slide-item style="margin: 0 20px 0 20px;" name="7">
              <div>
                  <video min-width="17%" max-width="50%" height="160px" controls poster="/img/thumbnail7.png">
                      <source src="/assets/video_7.mp4" type="video/mp4">
                      Your browser does not support HTML video.
                  </video>
              </div>
          </v-slide-item>
          <v-slide-item style="margin: 0 20px 0 20px;" name="8">
              <div>
                  <video min-width="17%" max-width="50%" height="160px" controls poster="/img/thumbnail8.png">
                      <source src="/assets/video_8.mp4" type="video/mp4">
                      Your browser does not support HTML video.
                  </video>
              </div>
          </v-slide-item>
          <v-slide-item style="margin: 0 20px 0 20px;" name="9">
              <div>
                  <video min-width="17%" max-width="50%" height="160px" controls poster="/img/thumbnail9.png">
                      <source src="/assets/video_9.mp4" type="video/mp4">
                      Your browser does not support HTML video.
                  </video>
              </div>
          </v-slide-item>
          </v-slide-group>
      </v-sheet>
    </div>
    <br>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import InstallBanner from './InstallBanner'
  import UpdateBanner from './UpdateBanner'
  import update from '../mixins/update'
  import { portuguese } from '../languages/pt-br'
  import { english } from '../languages/en'

  export default {
    name: 'Login',
    components: { InstallBanner, UpdateBanner },
    computed:{
      ...mapGetters(['profile']),
      selectedLanguage() {
        if(localStorage.getItem('loginLanguage')){
          if(localStorage.getItem('loginLanguage') === 'en'){
            return english;
          } else {
            return portuguese;
          }
        } else {
          return portuguese;
        }
        },
    },
    mounted(){
      localStorage.removeItem('vuex');
      localStorage.removeItem('loglevel:webpack-dev-server');
      localStorage.removeItem('ol@search-nominatim');
    },
    data: () => ({
      logo_url: '/img/logo_color.png',
      form: false,
      email: null,
      password: null,
      loading: false,
      loading1: false,
      resetedPassword: undefined,
      logginMessage: undefined,
      resetMessage: undefined,
      rules: {
        required: value => !!value || 'Field is required',
        email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'E-mail must be valid',
        password: [
          value => !!value || 'Please enter a password',
          (value) => !!value || 'Please type password.',
          (value) => (value && /[^A-Za-z0-9]/.test(value)) || 'At least one special character',
          (value) => (value && value.length > 9 ) || 'minimum 10 characters',
        ]
      },
    }),
    mixins: [update],
    methods: {
      changeLanguage(props) {
        const currentLanguage = window.localStorage.getItem('loginLanguage');
        if (currentLanguage !== props) {
          window.localStorage.setItem('loginLanguage', props);
          window.location.reload();
        }
      },
      ...mapActions(['login','passwordResetRequest']),
      async logginRequest () {
        this.logginMessage=undefined
        this.resetMessage=undefined

        // const emailRules = this.rules.email(this.email)
        // if(emailRules!=true){
        //   this.logginMessage = emailRules
        //   return
        // }
        // var passwordRules = undefined
        // const rulesPasswordCopy = [...this.rules.password];
        // rulesPasswordCopy.forEach((key, index, arr) => {
        //   passwordRules = key(this.password)
        //   if(passwordRules!=true){
        //     arr.length = index + 1
        //   }
        // })
        // if(passwordRules!=true){
        //   this.logginMessage = passwordRules
        //   return
        // }
        if (!this.form) return
        this.loading = true
        const res = await this.login({"email": this.email,"password": this.password})
        this.loading = false
        if ( res == true ) {
          this.$router.push({ name: 'home' })
        } else if ( res == 'invalid credentials' ) {
          this.logginMessage = this.selectedLanguage['error_login_credentials_txt_1']
        } else {
          this.logginMessage = this.selectedLanguage['error_login_credentials_txt_2']
        }
      },
      required (v) {
        return !!v || 'Field is required'
      },
      async resetRequest () {
        this.logginMessage=undefined
        this.resetMessage=undefined
        const emailRules = this.rules.email(this.email)
        if(emailRules!=true){
          this.resetMessage = emailRules
          return
        }
        this.loading1 = true
        const res = await this.passwordResetRequest(this.email)
        this.loading1 = false
        if(res!=true){
          this.resetMessage = res
          return
        }
        else{
          this.resetedPassword = true
          return
        }
      }
    },
  }
</script>
