<template>
  <div v-if="updateExists">
    <v-btn color="info" dark @click="refreshApp">{{ this.selectedLanguage['update_banner_text'] }}</v-btn>
  </div>
</template>

<script>
  import update from '../mixins/update'
  import { mapGetters } from 'vuex'
  import { portuguese } from '../languages/pt-br'
  import { english } from '../languages/en'

  export default {
    name: 'UpdateBanner',
    created: async function(){
    },
    computed:{
      ...mapGetters(['profile']),
      selectedLanguage() {
        if(this.profile != undefined){
          if (this.profile['language'] === 'en') {
            return english;
          } else {
            return portuguese;
          }
        } else {
          return portuguese;
        }
        },
    },
    data: () => ({
    }),
    mixins: [update],
    methods: {
    }
  }
</script>
