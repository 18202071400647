<template>
  <div v-if="deferredPrompt!=null">
    <v-btn color="info" dark @click="install">Install for better experience</v-btn>
  </div>
</template>

<script>
  export default {
    name: 'InstallBanner',
    created: async function(){
      window.addEventListener("beforeinstallprompt", e => {
        e.preventDefault();
        this.deferredPrompt = e;
      });
      window.addEventListener("appinstalled", () => {
        this.deferredPrompt = null;
      });
    },
    data: () => ({
      deferredPrompt: null
    }),
    methods: {
      async dismiss() {this.deferredPrompt = null;},
      async install() {this.deferredPrompt.prompt();},
    }
  }
</script>
